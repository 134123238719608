import {
	Box,
	Tab,
	Tabs,
	Theme,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { ProcessTable } from "../../../sections/Dashboard/ProcessMonitoring/ProcessInsights";
import { ProcessOverview } from "../../../sections/Dashboard/ProcessMonitoring/ProcessOverview";
import { isEmpty } from "../../../utils/isEmpty";
import { ProcessResponseType, TenantProcessTypesType } from "../../../types";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div hidden={value !== index} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		padding: 0,
		"& .MuiBox-root": {
			padding: "10px 0 0 0",
		},
	},
}));

export interface ProcessMonitoringPropsType {
	tenantProcessTypes: TenantProcessTypesType;
	processSummary: ProcessResponseType;
}

const ProcessMonitoring = ({
	processSummary,
	tenantProcessTypes,
}: ProcessMonitoringPropsType) => {
	const [tabIndex, setTabIndex] = useState(0);
	const classes = useStyles();

	const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<div className={classes.root}>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
			>
				<Tab label="Overview" />
				<Tab label="Logs" />
			</Tabs>
			<TabPanel value={tabIndex} index={0}>
				<ProcessOverview
					tenantProcessTypes={tenantProcessTypes}
					processCount={processSummary.count}
					processLogs={processSummary.logs}
					hourlyProcess={processSummary.hourlyProcess}
				/>
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				{isEmpty(tenantProcessTypes) ? (
					"Loading ..."
				) : (
					<ProcessTable
						tenantProcessTypes={tenantProcessTypes}
						processLogs={processSummary.logs}
					/>
				)}
			</TabPanel>
		</div>
	);
};

export { ProcessMonitoring };
